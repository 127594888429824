import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    { 
      path: '*', 
      redirect: '/404' 
    },   
    {
      path: "/",
      name: "home",
        component: () => import("./views/Finish.vue"),
    },
    {
      
      path: "/play",
      name: "play",
      component: () => import("./views/Finish.vue"),
    },
    {
      
      path: "/mentions",
      name: "mentions",
      component: () => import("./views/Mentions.vue"),
    },
    {
      
      path: "/mentions",
      name: "mentions",
      component: () => import("./views/Home.vue"),
    },
    {
      path: "/result",
      name: "result",
      component: () => import("./views/Result.vue"),
    },
    {
      path: "/contact",
      name: "contact",
      component: () => import("./views/Contact.vue"),
    },
    {
      path: "/wait",
      name: "wait",
      component: () => import("./views/Wait.vue"),
    },
    {
      path: "/form",
      name: "form",
      component: () => import("./views/Finish.vue"),
    },
    {
      path: "/finish",
      name: "finish",
      component: () => import("./views/Finish.vue"),
    },
    {
      path: "/beforegame",
      name: "beforegame",
      component: () => import("./views/Beforegame.vue"),
    }
  ],
});

export default router;
