<template>
  <div id="app" class="-mt-4">
     <headerElem   />
      <transition name="fade" mode="out-in">
        <router-view />
      </transition>
    <FooterElem  />
 </div> 
</template>

<script>
import HeaderElem from '@/components/Header'
import FooterElem from '@/components/Footer'

export default {
  name: 'App',
  data: function() {
    return {
    };
  },   
  components: {
    HeaderElem,
    FooterElem,
  }
  }
</script>