<template>
  <header
    class="w-full bg-black border-b-2 border-white h-[100px] sm:h-62 lg:h-[10vw] max-h-[134px] relative z-30"
  >
    <div class="relative max-w-screen-2xl m-auto text-center">
      <img
        @click="goTo()"
        class="w-[200px] mt-6 sm:mt-4 lg:w-[22%] lg:ml-[8.7%] lg:pt-[0.5%] m-auto"
        :class="{
          'w-[250px] pt-2 lg:w-[19vw] !ml-auto 2xl:w-[290px] lg:pt-[1vw] 2xl:pt-[20px]':
            currentRouteName == '/contact' || currentRouteName == '/finish',
          'lg:!w-[200px] lg:!pt-12 ': currentRouteName == '/form',
        }"
        src="@/assets/images/logo.png"
      />
    </div>
  </header>
</template>

<script>
export default {
  computed: {
    currentRouteName() {
      return this.$route.path;
    },
  },
  watch: {
    currentRouteName() {
      const day = Number(this.$dayjs().format("DD"));
     // if (day >= 20 && day < 28) {
        if (
          this.$route.path !== "/mentions" &&
          this.$route.path !== "/form" &&
          this.$route.path !== "/contact"
        ) {
         // this.$router.push("/wait");
        }
    //  }
    },
  },
  mounted() {},
  methods: {
    goTo() {
      this.$router.push("/");
    },
  },
};
</script>
